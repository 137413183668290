// Button to toggle LifeMap
class ToggleLifeMapControl {
  constructor (mapboxgl, supporter, userId) {
    this._mapboxgl = mapboxgl
    this._supporter = supporter
    this._userId = userId
  }

  onAdd (map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.classList.add('mapboxgl-ctrl')
    this._container.classList.add('mapboxgl-ctrl-group')
    this._button = document.createElement('button')
    this._button.classList.add('mapboxgl-ctrl-icon')
    this._button.classList.add('plausible-event-name=ToggleLifeMapControl')
    this._button.setAttribute('title', 'Toggle LifeMap')
    this._button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-purple-900 w-6 h-6 pl-1"><path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" /></svg>'
    this._container.appendChild(this._button)
    this._lifeMapPopup = new this._mapboxgl.Popup({ closeButton: false })
    this.addLifeMap()

    this._button.addEventListener('debounced:click', () => {
      if (this._map.getLayoutProperty('CityStrides-selfLifeMap', 'visibility') === 'visible') {
        this._map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'none')
      } else {
        this._map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'visible')
      }
    }, { passive: true })

    return this._container
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }

  hoverActivity (e) {
    if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true' || e.features.length <= 0) {
      // don't animate the LifeMap if the person is drawing a route
      return
    }

    this._map.getCanvas().style.cursor = 'pointer'

    if (this.hoveredActivityId) {
      this._map.setFeatureState(
        {
          source: 'CityStrides-selfLifeMap',
          sourceLayer: 'activities',
          id: this.hoveredActivityId
        },
        { hover: false }
      )
    }
    // setFeatureState requires an ID, it cannot use values in properties
    this.hoveredActivityId = e.features[0].id
    this._map.setFeatureState(
      {
        source: 'CityStrides-selfLifeMap',
        sourceLayer: 'activities',
        id: this.hoveredActivityId
      },
      { hover: true }
    )
    this._lifeMapPopup.trackPointer().setText(new Date(e.features[0].properties.start_time).toDateString()).addTo(this._map)
  }

  unHoverActivity () {
    if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true') {
      // don't animate the LifeMap if the person is drawing a route
      return
    }

    this._map.getCanvas().style.cursor = 'grab'

    if (this.hoveredActivityId) {
      this._map.setFeatureState(
        {
          source: 'CityStrides-selfLifeMap',
          sourceLayer: 'activities',
          id: this.hoveredActivityId
        },
        { hover: false }
      )
    }

    this.hoveredActivityId = null
    this._lifeMapPopup.remove()
  }

  visitActivity (e) {
    if (document.getElementById('mapContainer').getAttribute('data-map-currently-drawing-value') === 'true') {
      // don't animate the LifeMap if the person is drawing a route
      return
    }

    const activityId = e.features[0].id

    if (activityId) {
      window.Turbo.visit(`/activities/${activityId}`)
    }
  }

  // duplicate code in map_city_controller.js map_lifemap_controller.js and toggle_lifemap.js
  addLifeMap () {
    if (this._map.getLayer('CityStrides-selfLifeMap')) {
      return
    }

    this.addLifeMapLayer()
  }

  addLifeMapLayer () {
    // change localhost to IP for mobile testing
    const tileHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://citystrides.com'
    this._map.addSource('CityStrides-selfLifeMap', {
      type: 'vector',
      maxzoom: 15,
      tiles: [`${tileHost}/users/${this._userId}/tiles/{z}/{x}/{y}`]
    })
    this._map.addLayer({
      id: 'CityStrides-selfLifeMap',
      type: 'line',
      source: 'CityStrides-selfLifeMap',
      'source-layer': 'activities',
      layout: {
        visibility: 'none',
        'line-cap': 'round',
        'line-join': 'round',
        'line-sort-key': ['get', 'start_time']
      },
      paint: {
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'hide'], false],
          0,
          1
        ],
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#4C1D95',
          '#8B5CF6'
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          4,
          2
        ]
      }
    }, 'road-label')

    if (this._supporter === true) {
      this._map.on('click', 'CityStrides-selfLifeMap', (e) => { this.visitActivity(e) })
      this._map.on('mousemove', 'CityStrides-selfLifeMap', (e) => { this.hoverActivity(e) })
      this._map.on('mouseleave', 'CityStrides-selfLifeMap', () => { this.unHoverActivity() })
    }
  }
}

export default ToggleLifeMapControl
