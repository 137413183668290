import { Controller } from '@hotwired/stimulus'
import RerunLifemapControl from '../src/mapbox/rerun_lifemap.js'
import TimeFilterControl from '../src/mapbox/time_filter.js'

export default class extends Controller {
  static outlets = ['map']

  static values = {
    center: Array,
    supporter: Boolean,
    userId: String,
    viewingSelf: Boolean
  }

  mapOutletConnected (outlet, _element) {
    if (typeof outlet.map === 'undefined') {
      outlet.initializeTheMap()
    }

    this.reRunLifemapControl = new RerunLifemapControl(this.supporterValue, this.userIdValue, this.viewingSelfValue)
    this.timeFilterControl = new TimeFilterControl(this.supporterValue, this.userIdValue, this.viewingSelfValue)
    this.hoveredActivityId = null
    outlet.adjustClassesLife()

    if (outlet.mapIsInitialized) {
      this.addLifeMap()
      this.addMapListeners()
      this.addSupporterControls()
    } else {
      outlet.map.once('style.load', () => {
        this.addLifeMap()
        this.addMapListeners()
        this.addSupporterControls()
      })
    }
  }

  mapOutletDisconnected (outlet, _element) {
    this.hoveredActivityId = null
    outlet.map.off('autopanend', this.updateMapUrl)
    outlet.map.off('dragend', this.updateMapUrl)
    outlet.map.off('moveend', this.updateMapUrl)
    outlet.map.off('viewreset', this.updateMapUrl)
    outlet.map.off('zoomend', this.updateMapUrl)

    if (this.viewingSelfValue === false) {
      // only when viewing others because these listeners already exist on selfLifeMap
      outlet.removeLayerAndSource(`CityStrides-lifeMap${this.userIdValue}`)
      outlet.map.off('click', `CityStrides-lifeMap${this.userIdValue}`, this.visitActivity)
      outlet.map.off('mousemove', `CityStrides-lifeMap${this.userIdValue}`, this.hoverActivity)
      outlet.map.off('mouseleave', `CityStrides-lifeMap${this.userIdValue}`, this.unHoverActivity)
    }

    if (outlet.map.hasControl(this.reRunLifemapControl) === true) {
      outlet.map.removeControl(this.reRunLifemapControl)
    }

    if (outlet.map.hasControl(this.timeFilterControl) === true) {
      outlet.map.removeControl(this.timeFilterControl)
    }
  }

  addMapListeners () {
    this.mapOutlet.map.on('autopanend', this.updateMapUrl)
    this.mapOutlet.map.on('dragend', this.updateMapUrl)
    this.mapOutlet.map.on('moveend', this.updateMapUrl)
    this.mapOutlet.map.on('viewreset', this.updateMapUrl)
    this.mapOutlet.map.on('zoomend', this.updateMapUrl)

    if (this.viewingSelfValue === false) {
      // these listeners already exist on selfLifeMap
      this.mapOutlet.map.on('click', `CityStrides-lifeMap${this.userIdValue}`, this.visitActivity)
      this.mapOutlet.map.on('mousemove', `CityStrides-lifeMap${this.userIdValue}`, this.hoverActivity)
      this.mapOutlet.map.on('mouseleave', `CityStrides-lifeMap${this.userIdValue}`, this.unHoverActivity)
    }
  }

  // duplicate code in map_city_controller.js map_lifemap_controller.js and toggle_lifemap.js
  addLifeMap () {
    this.mapOutlet.map.resize()

    if (this.viewingSelfValue === true) {
      if (this.mapOutlet.map.getLayer('CityStrides-selfLifeMap')) {
        this.mapOutlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'visible')
      }
    } else {
      if (this.mapOutlet.map.getLayer('CityStrides-selfLifeMap')) {
        this.mapOutlet.map.setLayoutProperty('CityStrides-selfLifeMap', 'visibility', 'none')
      }

      this.addLifeMapLayer()
    }

    this.positionTheView()
  }

  addLifeMapLayer () {
    // change localhost to IP for mobile testing
    const tileHost = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://citystrides.com'
    this.mapOutlet.map.addSource(`CityStrides-lifeMap${this.userIdValue}`, {
      type: 'vector',
      maxzoom: 15,
      tiles: [`${tileHost}/users/${this.userIdValue}/tiles/{z}/{x}/{y}`]
    })
    this.mapOutlet.map.addLayer({
      id: `CityStrides-lifeMap${this.userIdValue}`,
      type: 'line',
      source: `CityStrides-lifeMap${this.userIdValue}`,
      'source-layer': 'activities',
      layout: {
        visibility: 'visible',
        'line-join': 'round',
        'line-cap': 'round',
        'line-sort-key': ['get', 'start_time']
      },
      paint: {
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'hide'], false],
          0,
          1
        ],
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#4C1D95',
          '#8B5CF6'
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          4,
          2
        ]
      }
    }, 'road-label')
  }

  addSupporterControls () {
    if (this.mapOutlet.map.hasControl(this.timeFilterControl) === false) {
      this.mapOutlet.map.addControl(this.timeFilterControl, 'top-left')
    }

    if (this.mapOutlet.map.hasControl(this.reRunLifemapControl) === false) {
      this.mapOutlet.map.addControl(this.reRunLifemapControl, 'top-left')
    }
  }

  hoverActivity = (e) => {
    if (this.mapOutlet.currentlyDrawingValue === true || e.features.length <= 0) {
      // don't animate the LifeMap if the person is drawing a route
      return
    }

    this.mapOutlet.map.getCanvas().style.cursor = 'pointer'

    if (this.supporterValue === true) {
      if (this.hoveredActivityId) {
        this.mapOutlet.map.setFeatureState(
          {
            source: `CityStrides-lifeMap${this.userIdValue}`,
            sourceLayer: 'activities',
            id: this.hoveredActivityId
          },
          { hover: false }
        )
      }
      // setFeatureState requires an ID, it cannot use values in properties
      this.hoveredActivityId = e.features[0].id
      this.mapOutlet.map.setFeatureState(
        {
          source: `CityStrides-lifeMap${this.userIdValue}`,
          sourceLayer: 'activities',
          id: this.hoveredActivityId
        },
        { hover: true }
      )
      this.mapOutlet.lifeMapPopup.trackPointer().setText(new Date(e.features[0].properties.start_time).toDateString()).addTo(this.mapOutlet.map)
    } else if (this.viewingSelfValue === true) {
      this.mapOutlet.lifeMapPopup.trackPointer().setText('Become a Supporter to have a more interactive LifeMap.').addTo(this.mapOutlet.map)
    }
  }

  positionTheView () {
    const hashValue = window.location.hash.substring(1).split('&')
    if (typeof hashValue[1] !== 'undefined' && !isNaN(hashValue[1])) {
      const centerHash = hashValue[0].split(',')
      // const center = (typeof centerHash[0] === 'undefined' || typeof centerHash[1] === 'undefined' || isNaN(centerHash[0]) || isNaN(centerHash[1])) ? this.centerValue : [parseFloat(centerHash[1]), parseFloat(centerHash[0])]
      const center = [parseFloat(centerHash[1]), parseFloat(centerHash[0])]
      this.mapOutlet.map.jumpTo({ center, zoom: parseFloat(hashValue[1]), preload: true })
    } else {
      this.mapOutlet.map.jumpTo({ center: this.centerValue, zoom: 11, preload: true })
    }
  }

  unHoverActivity = () => {
    if (this.mapOutlet.currentlyDrawingValue === true) {
      // don't animate the LifeMap if the person is drawing a route
      return
    }

    this.mapOutlet.map.getCanvas().style.cursor = 'grab'

    if (this.supporterValue === true) {
      if (this.hoveredActivityId) {
        this.mapOutlet.map.setFeatureState(
          {
            source: `CityStrides-lifeMap${this.userIdValue}`,
            sourceLayer: 'activities',
            id: this.hoveredActivityId
          },
          { hover: false }
        )
      }

      this.hoveredActivityId = null
    }

    this.mapOutlet.lifeMapPopup.remove()
  }

  updateMapUrl () {
    const mapZoom = this.getZoom()
    const mapCenter = this.getCenter()
    window.location.replace(`#${mapCenter.lat},${mapCenter.lng}&${mapZoom}`)
  }

  visitActivity = (e) => {
    if (this.mapOutlet.currentlyDrawingValue === true) {
      // don't animate the LifeMap if the person is drawing a route
      return
    }

    const activityId = e.features[0].id

    if (this.supporterValue === true && this.mapOutlet.currentlyDrawingValue === false && activityId) {
      window.Turbo.visit(`/activities/${activityId}`)
    }
  }
}
